var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.title) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "controls" },
              [
                _c(
                  "span",
                  { staticClass: "timeline-filter-flex-grow-container" },
                  [
                    _c("BadgeFilter", {
                      attrs: {
                        filters: _vm.filterObject,
                        historyList: _vm.filterHistory,
                        fields: _vm.filterFields,
                        fieldValues: _vm.filterFieldValues,
                      },
                      on: {
                        modified: _vm.onFilterModified,
                        historyModified: _vm.onHistoryModified,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "PriorityNavigation",
                  {
                    staticClass: "timeline-priority-nav",
                    attrs: { ulClassName: "timeline-priority-nav-ul" },
                  },
                  [
                    _c(
                      "li",
                      [
                        _c("ListFilter", {
                          attrs: { term: _vm.filter, termOnly: true },
                          on: { applyFilter: _vm.applyFilter },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass:
                          "d-flex ml-1 mr-1 date start-date-elevation",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "ml-1 mr-1 timeline-span",
                            attrs: { for: "startDate" },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.date")))]
                        ),
                        _c("b-form-datepicker", {
                          staticClass: "date-picker",
                          attrs: {
                            "today-button": "",
                            "close-button": "",
                            "hide-header": "",
                            size: "sm",
                            boundary: "viewport",
                            "popper-opts": { positionFixed: true },
                            "label-today-button": _vm.$t("date.today"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                          },
                          on: { input: _vm.dateChanged },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function ({}) {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "calendar-days"] },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.startDate,
                            callback: function ($$v) {
                              _vm.startDate = $$v
                            },
                            expression: "startDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "d-flex mr-1 timescale-action" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "ml-1 mr-1 timeline-span",
                            attrs: { for: "timescale" },
                          },
                          [_vm._v(_vm._s(_vm.$t("staff.timescale")))]
                        ),
                        _c("multiselect", {
                          staticClass:
                            "custom-dropdown-options enable-option-icon fit-content-fix",
                          attrs: {
                            "max-height": 300,
                            options: _vm.spanOptions.map((i) => i.value),
                            "custom-label": _vm.getSpanOptionLabel,
                            placeholder: "",
                            searchable: false,
                            "allow-empty": false,
                            showLabels: false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (props) {
                                return [
                                  _vm.span == props.option
                                    ? _c("font-awesome-icon", {
                                        staticClass: "selected-option-icon",
                                        attrs: { icon: ["far", "check"] },
                                      })
                                    : _vm._e(),
                                  _c("span", { staticClass: "option__title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getSpanOptionLabel(props.option)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.span,
                            callback: function ($$v) {
                              _vm.span = $$v
                            },
                            expression: "span",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "view no-left-padding timeline-buttons",
                        on: _vm._d({ mouseleave: _vm.onViewLeave }, [
                          _vm.viewMouseEnterEvent,
                          _vm.onViewOver,
                        ]),
                      },
                      [
                        _c(
                          "b-dropdown",
                          {
                            ref: "view",
                            staticClass: "view-btn action-bar-dropdown",
                            attrs: {
                              id: `BTN_VIEW_TIMELINE`,
                              "toggle-class": "text-decoration-none",
                              "no-caret": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "desktop"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.savePreset },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "action-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("project.button.save_timeline")
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("b-dropdown-divider"),
                            _vm._l(_vm.timelineViews, function (item, index) {
                              return [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    key: index,
                                    staticClass: "action-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadViewSettings(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "action-item-label-with-icon",
                                        attrs: { id: `viewname_ht${index}` },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _vm.isEllipsisActive(
                                      item.name,
                                      `viewname_ht${index}`
                                    )
                                      ? _c("b-popover", {
                                          attrs: {
                                            target: `viewname_ht${index}`,
                                            placement: "top",
                                            boundary: "viewport",
                                            "custom-class": "popover-margin",
                                            triggers: "hover",
                                            offset: "-100",
                                            content: item.name,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      [
                                        !_vm.editPermission(item)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "action-item-icon",
                                                attrs: {
                                                  id: `BTN_VIEW_TIMELINE_COPY_${index}`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.copyColumnSettings(
                                                      item.name,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["far", "copy"],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.editPermission(item)
                                          ? _c("b-popover", {
                                              attrs: {
                                                target: `BTN_VIEW_TIMELINE_COPY_${index}`,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.copy_timeline"
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        !_vm.editPermission(item)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "action-item-icon position-third",
                                                attrs: {
                                                  id: `BTN_TIMELINE_INFO_${index}`,
                                                },
                                                on: _vm._d(
                                                  {
                                                    mouseleave: function (
                                                      $event
                                                    ) {
                                                      return _vm.onInfoLeave(
                                                        index
                                                      )
                                                    },
                                                  },
                                                  [
                                                    _vm.infoMouseEnterEvent,
                                                    function ($event) {
                                                      return _vm.onInfoOver(
                                                        index
                                                      )
                                                    },
                                                  ]
                                                ),
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      "far",
                                                      "circle-info",
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.editPermission(item)
                                          ? _c("b-popover", {
                                              ref: `BTN_TIMELINE_INFO_${index}`,
                                              refInFor: true,
                                              attrs: {
                                                target: `BTN_TIMELINE_INFO_${index}`,
                                                show: _vm.showInfo[index],
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "manual",
                                                content: _vm.$t("owner", [
                                                  _vm.timelineViews[index]
                                                    .owner,
                                                ]),
                                              },
                                              on: {
                                                "update:show": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.showInfo,
                                                    index,
                                                    $event
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "action-item-icon position-third",
                                                attrs: {
                                                  id: `BTN_VIEW_TIMELINE_SHARE_${index}`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.shareColumnSettings(
                                                      index,
                                                      item.name,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: [
                                                      item.defaultView
                                                        ? "fas"
                                                        : "far",
                                                      "share-nodes",
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? _c("b-popover", {
                                              attrs: {
                                                target: `BTN_VIEW_TIMELINE_SHARE_${index}`,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.share_timeline"
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "action-item-icon position-second",
                                                attrs: {
                                                  id: `BTN_VIEW_TIMELINE_UPDATE_${index}`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.updateColumnSettings(
                                                      index,
                                                      item.name,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["far", "save"],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? _c("b-popover", {
                                              attrs: {
                                                target: `BTN_VIEW_TIMELINE_UPDATE_${index}`,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.update_timeline"
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "action-item-icon",
                                                attrs: {
                                                  id: `BTN_VIEW_TIMELINE_DELETE_${index}`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.removeColumnSettings(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["far", "trash-alt"],
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.editPermission(item)
                                          ? _c("b-popover", {
                                              attrs: {
                                                target: `BTN_VIEW_TIMELINE_DELETE_${index}`,
                                                placement: "top",
                                                boundary: "viewport",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "project.button.delete_timeline"
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                          _vm.colorMouseEnterEvent,
                          _vm.onColoringOver,
                        ]),
                      },
                      [
                        _c(
                          "b-dropdown",
                          {
                            ref: "coloring",
                            staticClass:
                              "no-left-padding timeline-buttons action-bar-dropdown",
                            attrs: {
                              id: `BTN_COLORING_TIMELINE`,
                              "toggle-class": "text-decoration-none",
                              "no-caret": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "palette"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c(
                              "b-dropdown-group",
                              { attrs: { header: _vm.$t("colorby") } },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "none",
                                          "coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [_vm._v(_vm._s(_vm.$t("none")))]
                                    ),
                                    _vm.display.coloring.none
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "location",
                                          "coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("staff.coloring.location")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.display.coloring.location
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "stage",
                                          "coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("project.display.status")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.display.coloring.stage
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "project",
                                          "coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("staff.coloring.project")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.display.coloring.project
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onColorChange(
                                          "customer",
                                          "coloring"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "action-item-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("staff.coloring.customer")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.display.coloring.customer
                                      ? _c("font-awesome-icon", {
                                          staticClass: "active-check",
                                          attrs: { icon: ["far", "check"] },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "timeline-attribute-toggler" },
                  [
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "timeline-buttons",
                        attrs: {
                          dropleft: "",
                          id: "ddown-offset",
                          "popper-opts": { positionFixed: true },
                          variant: "link",
                          "no-caret": "",
                          boundary: "viewport",
                          offset: _vm.sizeClass["SMH"] ? "-52" : "0",
                        },
                      },
                      [
                        _c("template", { slot: "button-content" }, [
                          _c(
                            "div",
                            { staticClass: "text" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "ellipsis-vertical"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "b-dropdown-group",
                          {
                            attrs: { header: _vm.$t("project.display.title") },
                          },
                          [
                            _vm._l(_vm.displayList, function (item, key) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: key,
                                    staticClass: "dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.toggleVisible(key)
                                          },
                                        },
                                        model: {
                                          value: _vm.display[key],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.display, key, $$v)
                                          },
                                          expression: "display[key]",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.displayLabels[key]))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _c(
                          "b-dropdown-group",
                          {
                            attrs: { header: _vm.$t("project.progress.title") },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item progress-toggle",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toggleVisible("_progress0")
                                      },
                                    },
                                    model: {
                                      value: _vm.display["_progress0"],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.display, "_progress0", $$v)
                                      },
                                      expression: "display['_progress0']",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "legend-item project-tile-0",
                                    }),
                                    _c("span", [_vm._v("0%")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item progress-toggle",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toggleVisible("_progress25")
                                      },
                                    },
                                    model: {
                                      value: _vm.display["_progress25"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.display,
                                          "_progress25",
                                          $$v
                                        )
                                      },
                                      expression: "display['_progress25']",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "legend-item project-tile-25",
                                    }),
                                    _c("span", [_vm._v("1% - 25%")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item progress-toggle",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toggleVisible("_progress50")
                                      },
                                    },
                                    model: {
                                      value: _vm.display["_progress50"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.display,
                                          "_progress50",
                                          $$v
                                        )
                                      },
                                      expression: "display['_progress50']",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "legend-item project-tile-50",
                                    }),
                                    _c("span", [_vm._v("26% - 50%")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item progress-toggle",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toggleVisible("_progress75")
                                      },
                                    },
                                    model: {
                                      value: _vm.display["_progress75"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.display,
                                          "_progress75",
                                          $$v
                                        )
                                      },
                                      expression: "display['_progress75']",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "legend-item project-tile-75",
                                    }),
                                    _c("span", [_vm._v("51% - 75%")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item progress-toggle",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toggleVisible("_progress99")
                                      },
                                    },
                                    model: {
                                      value: _vm.display["_progress99"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.display,
                                          "_progress99",
                                          $$v
                                        )
                                      },
                                      expression: "display['_progress99']",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "legend-item project-tile-99",
                                    }),
                                    _c("span", [_vm._v("76% - 99%")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item progress-toggle",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.toggleVisible("_progress100")
                                      },
                                    },
                                    model: {
                                      value: _vm.display["_progress100"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.display,
                                          "_progress100",
                                          $$v
                                        )
                                      },
                                      expression: "display['_progress100']",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "legend-item project-tile-100",
                                    }),
                                    _c("span", [_vm._v("100%")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "ddown-offset",
                          triggers: "hover",
                          placement: "top",
                          boundary: "viewport",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("project.settings.display")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.canView("PROJECT", ["scheduleStart", "scheduleFinish"])
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : _c(
                  "div",
                  { attrs: { id: "timeline-wrapper" } },
                  [
                    _c(
                      "table",
                      { staticClass: "timeline-table" },
                      [
                        _c(
                          "colgroup",
                          _vm._l(_vm.months, function (month) {
                            return _c("col", {
                              key: `colgroup-${_vm.span}-${month.date}`,
                              class: _vm.applyHeaderClass(month),
                            })
                          }),
                          0
                        ),
                        _c("thead", [
                          _c(
                            "tr",
                            _vm._l(_vm.months, function (month) {
                              return _c(
                                "th",
                                {
                                  key: `th-${_vm.span}-${month.date}`,
                                  class: month.isToday ? "todayHead" : "",
                                },
                                [_vm._v(_vm._s(month.date))]
                              )
                            }),
                            0
                          ),
                        ]),
                        _vm._l(_vm.rows, function (column, index) {
                          return _c(
                            "tr",
                            { key: index },
                            [
                              _vm._l(column, function (cell) {
                                return [
                                  cell.project
                                    ? [
                                        _c(
                                          "td",
                                          {
                                            key: `project-cell-${_vm.span}-${cell.date}-${cell.project.uuId}`,
                                            attrs: { colspan: cell.span },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                class: [
                                                  "project-bar",
                                                  _vm.getColorClass(
                                                    cell.project.progress
                                                  ),
                                                  {
                                                    "no-start": cell.noStart,
                                                    "no-end": cell.noEnd,
                                                  },
                                                ],
                                                style: {
                                                  background: _vm.getOuterColor(
                                                    cell.project.progress,
                                                    cell.project
                                                  ),
                                                  borderColor:
                                                    _vm.getOuterColor(
                                                      cell.project.progress,
                                                      cell.project
                                                    ),
                                                },
                                                attrs: {
                                                  id:
                                                    "timeline-" +
                                                    cell.project.uuId,
                                                  tabindex: "0",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.projectId =
                                                      cell.project.uuId
                                                    _vm.renderPopup($event)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "project-name",
                                                    style: {
                                                      background:
                                                        _vm.getInnerColor(
                                                          cell.project.progress,
                                                          cell.project
                                                        ),
                                                      color: _vm.getTextColor(
                                                        cell.project
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-clamp",
                                                      {
                                                        attrs: {
                                                          autoresize: "",
                                                          "max-lines": 1,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.display[
                                                                "customer"
                                                              ] &&
                                                                cell.project
                                                                  .customer
                                                                ? cell.project
                                                                    .customer +
                                                                    " • "
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              cell.project.name
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.display[
                                                                "status"
                                                              ] &&
                                                                cell.project
                                                                  .status
                                                                ? " • [" +
                                                                    cell.project
                                                                      .status +
                                                                    "]"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.display["progress"]
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "progress-box",
                                                      },
                                                      [
                                                        cell.progress == 0
                                                          ? _c("span", [
                                                              _c("i", {
                                                                staticClass:
                                                                  "far fa-minus",
                                                              }),
                                                            ])
                                                          : cell.progress == 100
                                                          ? _c("span", [
                                                              _c("i", {
                                                                staticClass:
                                                                  "far fa-check",
                                                              }),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    cell.progress
                                                                  ) +
                                                                  "% "
                                                              ),
                                                            ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm.display["milestones"]
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "milestone-container",
                                                  },
                                                  _vm._l(
                                                    cell.milestones,
                                                    function (m, index) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "timeline-milestone",
                                                          style: {
                                                            left:
                                                              m.position + "%",
                                                            borderBottomColor:
                                                              _vm.getNotchColor(
                                                                cell.project
                                                                  .progress,
                                                                cell.project
                                                              ),
                                                          },
                                                          attrs: {
                                                            id:
                                                              "timeline-milestone-" +
                                                              m.id,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-popover",
                                                            {
                                                              attrs: {
                                                                target:
                                                                  "timeline-milestone-" +
                                                                  m.id,
                                                                "custom-class":
                                                                  "milestone timeline-tooltip " +
                                                                  _vm.getColorClass(
                                                                    cell.project
                                                                      .progress
                                                                  ),
                                                                triggers:
                                                                  "hover",
                                                                placement:
                                                                  "bottom",
                                                                boundary:
                                                                  "viewport",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-tooltip-header milestone-tooltip-header",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "diamond",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: [
                                                                                "fas",
                                                                                "diamond",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "milestone-title",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "milestone-word",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Milestone:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "milestone-name",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              m.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-tooltip-body",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "table",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "title",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Date"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "content",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                m.date
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "table",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "title",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Progress"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "content",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                m.progress
                                                                                  ? (
                                                                                      m.progress *
                                                                                      100
                                                                                    ).toFixed(
                                                                                      0
                                                                                    )
                                                                                  : 0
                                                                              ) +
                                                                              "% "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target:
                                                    "timeline-" +
                                                    cell.project.uuId,
                                                  "custom-class":
                                                    "timeline-tooltip " +
                                                    _vm.getColorClass(
                                                      cell.project.progress
                                                    ),
                                                  triggers: "hover",
                                                  placement: "top",
                                                  boundary: "viewport",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "timeline-tooltip-header milestone-tooltip-header",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "diamond",
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: [
                                                                "far",
                                                                "chart-network",
                                                              ],
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "milestone-title",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "milestone-word",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "project.title"
                                                                )
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "milestone-name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                cell.project
                                                                  .name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "timeline-tooltip-body",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "customer",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "customer.title"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  cell.project
                                                                    .customer
                                                                    ? cell
                                                                        .project
                                                                        .customer
                                                                    : _vm.$t(
                                                                        "customer.no_customer"
                                                                      )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "table" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("start")
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("end")
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  cell.project
                                                                    .startTime >
                                                                    0
                                                                    ? _vm
                                                                        .moment(
                                                                          cell
                                                                            .project
                                                                            .startTime
                                                                        )
                                                                        .format(
                                                                          "LL"
                                                                        )
                                                                    : ""
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  cell.project
                                                                    .closeTime >
                                                                    0
                                                                    ? _vm
                                                                        .moment(
                                                                          cell
                                                                            .project
                                                                            .closeTime
                                                                        )
                                                                        .format(
                                                                          "LL"
                                                                        )
                                                                    : ""
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "table" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "project.field.budget"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "project.field.actual"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " $" +
                                                                _vm._s(
                                                                  _vm.costFormat(
                                                                    cell.project
                                                                      .fixedCost
                                                                      ? cell
                                                                          .project
                                                                          .fixedCost
                                                                      : cell
                                                                          .project
                                                                          .estimatedCost
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " $" +
                                                                _vm._s(
                                                                  _vm.costFormat(
                                                                    cell.project
                                                                      .actualCost
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "table" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "project.display.progress"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "project.field.status"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  (
                                                                    cell.project
                                                                      .progress *
                                                                    100
                                                                  ).toFixed(0)
                                                                ) +
                                                                "% "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  cell.project
                                                                    .status
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c("td", {
                                          key: `empty-cell-${_vm.span}-${cell.date}`,
                                          attrs: { colspan: cell.span },
                                        }),
                                      ],
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                    _c("SaveViewModal", {
                      attrs: {
                        show: _vm.promptSaveShow,
                        name: _vm.saveName,
                        title: _vm.$t("task.confirmation.save"),
                        profile: _vm.saveProfile,
                      },
                      on: {
                        "update:show": function ($event) {
                          _vm.promptSaveShow = $event
                        },
                        ok: _vm.confirmSaveOk,
                      },
                    }),
                    _c("SaveViewModal", {
                      attrs: {
                        show: _vm.promptShareShow,
                        name: _vm.saveName,
                        title: _vm.$t("task.confirmation.share"),
                        sharing: true,
                        profile: _vm.saveProfile,
                      },
                      on: {
                        "update:show": function ($event) {
                          _vm.promptShareShow = $event
                        },
                        ok: _vm.confirmSaveOk,
                      },
                    }),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          title: _vm.$t("task.confirmation.title_delete"),
                          "content-class": "shadow",
                          "no-close-on-backdrop": "",
                        },
                        on: { ok: _vm.confirmDeleteViewOk },
                        scopedSlots: _vm._u([
                          {
                            key: "modal-footer",
                            fn: function ({ ok, cancel }) {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm", variant: "success" },
                                    on: {
                                      click: function ($event) {
                                        return ok()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm", variant: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return cancel()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.confirmDeleteViewShow,
                          callback: function ($$v) {
                            _vm.confirmDeleteViewShow = $$v
                          },
                          expression: "confirmDeleteViewShow",
                        },
                      },
                      [
                        _c("div", { staticClass: "d-block" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("task.confirmation.delete_view")) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }